import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import type { ReactElement, ReactNode } from 'react';
import { useEffect } from 'react';

import { useGlobalStyle } from '@/components/hooks/global-style';
import { useRUMRecording } from '@/components/hooks/rum';
import { useScrollTo } from '@/components/hooks/scrollto-polyfill';
import { useTDPageview } from '@/components/hooks/td';
import DebugBar from '@/components/shared/element/debug-bar';
import ProgressBar, { Progress } from '@/components/shared/element/progress';
import { isSynthetics } from '@/components/shared/utility/host';
import {
  descriptionByWeb,
  ogImageByWeb,
  siteNameByWeb,
  titleByWeb,
} from '@/contents/metatag';
import { globalStyles } from '@/stitches.config';
import { GTM_ID } from '@/tag-manager';
import { IWeb } from '@/types/config';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({
  Component,
  pageProps,
  router,
}: AppPropsWithLayout) {
  const host = process.env.HOST;
  const WEB = process.env.NEXT_PUBLIC_WEB as IWeb;
  const siteName = siteNameByWeb(WEB);
  const title = titleByWeb(WEB);
  const description = descriptionByWeb(WEB);
  const ogImage = ogImageByWeb(WEB);
  const synthetics = isSynthetics();

  useRUMRecording();
  useTDPageview();
  useGlobalStyle();
  useScrollTo();
  globalStyles();
  useEffect(() => {
    window.onerror = function (message, url, line, column, error) {
      // eslint-disable-next-line no-console
      console.log(message, url, line, column, error);
    };

    const onRouteChangeStart = () => {
      Progress.start();
    };
    const onRouteChangeError = () => {
      Progress.complete();
    };
    const onRouteChangeComplete = (url, { shallow }) => {
      Progress.complete();
      if (!shallow) {
        window.scrollTo({
          top: 0,
        });
      }
    };
    router.events.on('routeChangeStart', onRouteChangeStart);
    router.events.on('routeChangeError', onRouteChangeError);
    router.events.on('routeChangeComplete', onRouteChangeComplete);
    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
      router.events.off('routeChangeError', onRouteChangeError);
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);

  const getLayout = Component.getLayout ?? ((page) => page);

  const VALID_URL = `${host}${router.asPath}`;

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="commit-hash" content={process.env.NEXT_PUBLIC_COMMIT_ID} />
        <meta name="description" content={description} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, viewport-fit=cover"
        />
        <meta name="HandheldFriendly" content="true" />
        <meta name="MobileOptimized" content="320" />
        <meta name="application-name" content={siteName} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="apple-mobile-web-app-title" content={siteName} />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="msapplication-TileImage" content="/apple-touch-icon.png" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#000000" />
        <link
          rel="apple-touch-icon"
          sizes="512x512"
          href="/apple-touch-icon-512.png"
        />
        <link rel="canonical" href={VALID_URL} />
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="icon" type="image/png" href="/favicon.png" />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link
          rel="manifest"
          href="/manifest.json"
          crossOrigin="use-credentials"
        />
        <link rel="mask-icon" href="/favicon.svg" />
        <meta key="ogtitle" property="og:title" content={title} />
        <meta
          key="ogdescription"
          property="og:description"
          content={description}
        />
        <meta property="og:url" content={VALID_URL} />
        <meta key="ogimage" property="og:image" content={ogImage} />
        <meta key="ogimagetype" property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content={siteName} />
        <meta property="og:locale" content="th_TH" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={siteName} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:url" content={VALID_URL} />
        <meta key="twitterimage" name="twitter:image" content={ogImage} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="google-site-verification"
          content="TYXIgdAuZil9SJFPI7iHQE0CsGieT2Mia5pn98U-7fM"
        />
        {/* LMS fb app id */}
        <meta property="fb:app_id" content="1331045240403318" />
        <link
          rel="preconnect"
          href="https://images.contentstack.io"
          crossOrigin=""
        />
        <link rel="dns-prefetch" href="https://images.contentstack.io" />
        <link
          rel="preconnect"
          href="https://assets.contentstack.io"
          crossOrigin=""
        />
        <link rel="dns-prefetch" href="https://assets.contentstack.io" />
      </Head>
      {!synthetics && (
        <Script
          id="tagmanager"
          strategy="afterInteractive"
          crossOrigin="anonymous"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
          }}
        />
      )}
      <ProgressBar />
      {getLayout(<Component {...pageProps} />)}
      <DebugBar />
    </>
  );
}
