import { useEffect, useState } from 'react';

export const isServer = typeof window === 'undefined';

export function useLineBrowser() {
  const [isLine, setIsLine] = useState(false);

  useEffect(() => {
    setIsLine(navigator.userAgent.includes('Line'));
  }, []);

  return isLine;
}
